body {
  font-family: noto-sans-cjk-jp, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  line-height: 2.3;
  font-weight: 400;
}

code {
  font-family: "Consolas","Monaco","Andale Mono","Ubuntu Mono",monospace !important;
}

.sidenav {
  transition: width 0.3s ease-in-out;
  background-color: rgb(239, 105, 50);
  padding-top: 20px;
}

.sidenavClosed {
  transition: width 0.3s ease-in-out;
  width: 60px;
  background-color: rgb(239, 105, 50);
  padding-top: 20px;
}

.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: rgb(0,0,0);
  text-decoration: none;
  overflow: hidden;
  line-height: 1.2;
  font-size: 16px;
}

a.active {
  background-color: rgb(179, 81, 41);
  border-radius: 10px 10px 10px 10px;
  line-height: 1.5;
}

.sideMenuitem {
  display: flex;
  align-items: center;
  padding: 10px 20px 20px 20px;
  cursor: pointer;
  color: rgb(0,0,0);
  text-decoration: none;
  overflow: hidden;
}

.linkText {
  padding-left: 16px;
  white-space: nowrap;
}

.sublinkText {
  padding-left: 16px;
  white-space: nowrap;
  font-size: 14px;
}

.sideitem:hover {
  background-color: rgb(255, 136, 56);

}

.menuBtn {
  align-self: center;
  align-self: flex-start;  
  justify-self: flex-end;
  color: rgb(0,0,0);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
  font-size: 1.5rem;
}

.bigIcon {
  font-size: 1.5rem;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
}

.smallIcon {
  font-size: 1.3rem;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  padding: 0px 0px 0px 10px;
}

.App {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
}

main {
  padding: 20px 110px;
  overflow-y: auto;
  width: 100%;
}

.MuiOutlinedInput-root {
  border-radius: 50px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:  rgb(255, 136, 56) !important;
  color:  rgb(255, 136, 56) !important;
}

.MuiFormLabel-root {
  font-family: noto-sans-cjk-jp, sans-serif !important;
}

.MuiFormLabel-root.Mui-focused {
  color:  rgb(255, 136, 56) !important;
}

.MuiPaper-elevation1 {
  box-shadow: rgb(0 0 0 / 40%) 0px 5px 15px !important;
}

.MuiPaper-rounded {
  border-radius: 20px 20px 20px 20px !important;
}

.MuiButton-contained {
  box-shadow: rgb(0 0 0 / 40%) 0px 5px 15px !important;
  border-radius: 50px !important;
}

.MuiButton-root {
  font-family: noto-sans-cjk-jp, sans-serif !important;
}

.MuiInputLabel-outlined.Mui-focused.Mui-required {
  color:  rgb(255, 136, 56) !important;
  border-color:  rgb(255, 136, 56) !important;
}

* {
  margin: 0;
  padding: 0;
}

.background {
  
  background-image: url('/adastec-bus.png') ;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.login-header {
  letter-spacing: 0.022em;
  text-align: center;
  font-size: 20px;
  margin-top: 25px;
  font-family: 'Nexa', Arial, sans-serif;
  line-height: 36px;
  font-weight: 400 !important;
  color: rgb(239, 105, 50) !important;
  margin-left: 2px;
}

@font-face {
  font-family: 'Nexa';
  src: url('https://uploads-ssl.webflow.com/600ad06ae00a6210fd3a2752/60168d7349f4819bbeb9bed6_Nexa%20Light.otf') format('opentype');
}

.method {
  font-size: 0.92em;
  border-radius: 5px;
  color: #f6f9fc;
  padding: 3px 10px;
  margin: 0px 10px 0px 0px;
}

.callout-child {
  display: flex;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  background: #f6f9fc;
  color: black !important;
}

.left-item {
  padding: 30px 30px 30px 30px !important;
  background-color: rgb(38, 50, 56) !important;
  color: white !important;
}

.right-item {
  padding: 30px 30px 30px 30px !important;
}

.callout {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background: #f6f9fc;
  border: 1px solid #dce6e9;
  border-radius: 4px;
}

.callout-method {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background: #f6f9fc;
  border: 1px solid #dce6e9;
  border-radius: 4px;
  overflow-x: auto;
  font-family: "Consolas","Monaco","Andale Mono","Ubuntu Mono",monospace !important;
}

.callout :global(p) {
  margin: 0;
}

.syntaxHighlighter {
  background-color: #f6f9fc !important;
  border: 1px solid #dce6e9 !important;
  border-radius: 4px !important;
  line-height: 2em;
}

hr {
  opacity: 0.5 !important;
}

.response-success {
  display: block;
  text-align: left;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 5px;
  line-height: 1.5em;
  cursor: pointer;
  color: rgb(29, 129, 39);
  background-color: rgba(29, 129, 39, 0.3);
}

.response-fail {
  display: block;
  text-align: left;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 5px;
  line-height: 1.5em;
  color: rgb(212, 31, 28);
  background-color: rgba(212, 31, 28, 0.3);
  cursor: pointer;
}